import React from 'react';
import {HackTheMoon} from './components/HackTheMoon.js';

function App() {
    return (
        <div className="App">
            <HackTheMoon />
        </div>
    );
}

export default App;
